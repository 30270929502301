#search {
    .heroImage {
        position: relative;
        display: flex;
        align-items: center;
        
        h1 {
            background: none;
            padding: 0;
            color: $white;
            text-align: center;
            margin-bottom: 30px;
            position: relative;
            z-index: 100;
            left: 0;
            bottom: 0;
        }
        .field {
            width: 40%;
            margin: 0 auto;
            position: relative;
            z-index: 100;
            color: $black;
            
            @include small-desktop {
                width: 50%;
            }
            @include tablet {
                width: 60%;
            }
            @include mobile {
                width: 85%;
            }
            @include small-mobile {
                width: 95%;
            }
            
            i {
                color: $red;
            }
        }
    }
    #searchResults {
        padding: 64px 0;
        max-width: 1000px;
        margin: 0 auto;
        
        .result {
            border-bottom: 1px solid $sand;
            margin-bottom: 30px;
            padding: 10px 0 35px;

            &:last-of-type {
                border-bottom: none;
                margin-bottom: 0;
            }
            h4 {
                font-family: $primary;
                margin-bottom: 10px;

                &:hover {
                    color: $red;
                }
            }
        }
        .noResults {
            display: none;
            i {
                color: $gold;
                font-size: 3em;
                padding: 20px;
            }
            h3 {
                margin-bottom: 20px;
            }
            p {
                width: 75%;
                margin: 15px auto;
            }
            a {
                color: $red;
            }
        }
    }
}