.splitView {
    display: flex;
    
    @include touch {
        display: block;
    }
    
    .imageWrapper {
        @extend .backgroundSettings;
        width: 50vw;
        display: inline-block;
        
        @include touch {
            display: block;
            width: 100%;
            height: 350px;
        }
    }
    .sectionContent {
        padding: 64px;
        width: 50vw;
        display: inline-block;
        
        @include touch {
            display: block;
            width: 100%;
            padding: 32px;
        }
        
        h1 {
            margin: 15px 0 40px 0;
        }
        h3 {
            @include screen {
                width: 70%;
            }
            @include desktop {
                width: 85%;
            }
            font-size: 36px;
            font-weight: 700;
            margin: 15px 0 40px 0;
        }
        .date {
            margin-bottom: 20px; //for special events
        }
        p {
            margin-bottom: 20px;
            
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        .button {
            margin-top: 30px;
        }
    }
    
    &.right {
        flex-direction: row;
    }
    &.left {
        flex-direction: row-reverse;
    }
}