#sponsors {
    .sponsorList {
        padding-bottom: 64px;
        text-align: center;
        
        h2 {
            margin-bottom: 15px;
        }
        ul {
            margin-bottom: 30px;
        }
        li {
            margin-bottom: 8px;
        }
    }
}