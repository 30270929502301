#membership {
    .benefits {
        padding-bottom: 64px;

        @include touch {
            padding-bottom: 32px;
        }
    }
    .member {
        .cardHeader {
            background: $dkBlue;
            border-radius: $radius $radius 0 0;
            text-align: center;
            padding: 40px 15px;

            h3 {
                color: $white;
                font-family: $primary;
                font-size: 38px;
                font-weight: 600;
                margin-bottom: 15px;
                line-height: 1.1em;
            }
            h4 {
                color: $gold;
                font-family: $primary;
                font-size: 25px;
                font-weight: 600;
                margin-bottom: 0;
            }
        }
        .cardContent {
            background: $paper;
            padding: 20px;

            .subtitle {
                color: $gold;
                font-size: 14px;
                text-transform: uppercase;
                letter-spacing: .05em;
                margin-bottom: 20px;
                text-align: center;
            }
            li {
                border-top: 1px solid $sand;
                padding: 20px 0;

                &:last-of-type {
                    padding: 20px 0 0 0;
                }

                i {
                    float: right;
                    color: $gold;
                    @extend .smooth;
                }
                .titleRow {
                    span {
                        font-size: 17px;
                        font-weight: 600;
                        width: 80%;
                        display: block;
                        float: left;
                    }
                }
                .description {
                    padding-top: 10px;
                    font-size: 15px;
                }
            }
        }
        .button {
            width: 100%;
            border-radius: 0 0 $radius $radius;

            &.primary {
                color: $white;
                border: 2px solid $red;
                background: $red;
                margin: 0;
                white-space: pre-wrap;
                line-height: 27px;
                display: block;
                height: auto;

                &:hover {
                    background: $gold;
                    border: 2px solid $gold;
                    color: $white;
                }

                @include touch {
                    font-size: 18px!important;
                    line-height: 25px!important;
                }
            }
        }

        .header {
            .accordion-button {
                cursor: pointer;

                .fa-plus {
                    backface-visibility: hidden;
                    transform: rotate(0deg);
                    transform-style: preserve-3d;
                }

                &.expanded {
                    .fa-plus {
                        transform: rotate(45deg);
                    }
                }
            }
        }
        .accordion-content {
            overflow: hidden;
            transition: height .6s;
        }

    }
    .gallerySection {
        background: $paper;
        padding: 64px 0 80px 0;

        @include touch {
            padding: 32px 0 42px 0;
        }

        .overview {
            margin: 0 auto 64px auto;

            @include screen {
                width: 65%;
            }
            @include touch {
                margin: 0 auto 32px auto;
            }
            h3 {
                text-align: center;
                margin-bottom: 15px;
            }
            p {
                text-align: center;
                margin-bottom: 25px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

        }
        .galleryCarousel {
            @include screen {
                width: 65%;
                margin: 0 auto;
            }
        }
    }
}
