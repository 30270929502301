.simpleIntro, .sectionIntro {
    text-align: center;
    margin: 64px auto;
    width: 60vw;
    
    @include touch {
        width: 100%;
    }
    
    h1 {
        margin: 15px 0 40px 0;
    }
    h2 {
        font-size: 42px;
        margin: 15px 0 40px 0;
    }
    p {
        margin-bottom: 30px;
    }
}