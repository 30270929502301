#news {
    #newsPost {
        padding: 64px 0;
        max-width: 1000px;
        margin: 0 auto;
        
        .titleRow {
            text-align: center;
            margin-bottom: 64px;
            
            h5 {
                font-size: 18px;
                margin-bottom: 15px;
            }
            h1 {
                font-size: 40px;
                
                @include screen {
                    max-width: 75%;
                    margin: 0 auto;
                }
            }
        }
        .content {
            h2 {
                color: $blue;
            }
            h3 {
                color: $blue;
                font-size: 36px;
            }
            h4 {
                color: $blue;
                font-size: 28px;
            }
            h5 {
                color: $red;
            }
            h6 {
                color: $sand;
            }
            ul, ol {
                @extend p;
            }
            figure {
                margin: 64px auto;
                max-width: 960px;
                
                figcaption {
                    margin-top: 8px;
                    color: $sand;
                    text-align: left;
                }
            }
        }
    }
}