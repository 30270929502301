body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: $body;
    line-height: 1em;
    overflow-x: hidden;
}
img {
    padding: 0;
}
#pageContent {
    position: relative;
    z-index: 1;
}

// Bacward Compatibility
header, nav, main, footer, article, section, aside {
   display:block;
}

// Utilities
.container {
    &.is-fluid {
        @include screen {
            padding-left: 64px;
            padding-right: 64px;
        }
        @include touch {
            padding-left: 32px!important;
            padding-right: 32px!important;
        }
    }
}
.nopad {
    padding: 0!important;
}
.thumbnail, img, .imageWrapper {
    line-height: 0;
}
.clear {
    clear: both;
}
.smooth {
    transition: all $transition ease-in-out;
}
.overlay {
    @extend .smooth;
    &.cover {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    &.darken {
        background: rgba(17, 17, 17, 0.5);
        z-index: 2;
    }
}
.gradient {
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.01) 1%,rgba(0,0,0,0.45) 100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    transition: background $transition ease-in-out;
}
hr {
    background-color: $sand;
}
.divi {
    height: 2px;
    background: $red;

    &.left {
        width: 50px;
        margin-bottom: 15px;
    }
    &.center {
        width: 70px;
        margin: 15px auto 0 auto;
    }
}
.backgroundSettings {
    background-position: center!important;
    background-size: cover!important;
    background-repeat: no-repeat!important;
}
.intrinsic-container {
    position: relative;
    height: 0;
    overflow: hidden;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.intrinsic-container-16x9 {
    padding-bottom: 56.5%;
}

//lightbox
.fancybox-thumbs__list a:before {
    display: none!important;
}
.fancybox-button--thumbs {
    display: none!important;
}
.fancybox-progress {
    background: $gold!important;
}

// copy message
.copyMessage {
    padding-top: 12px;
    h4 {
        font-size: 1em;
        opacity:1;
        &.fadeOut {
            opacity: 0;
            transition: opacity 1000ms;
        }
    }
}
