.utility {
    background: $paper;
    text-align: center;
    padding: 15px;
    margin-bottom: 64px;
    
    p {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
        color: $red;
        i {
            font-size: 14px;
            margin-right: 4px;
            @extend .smooth;
        }
        
        &:hover {
            i {
                opacity: 1;
                transform: translateX(-3px);
            }
        }
    }
}