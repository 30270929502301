#cta {
    @extend .backgroundSettings;
    min-height: 360px;
    text-align: center;
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    
    @include screen {
        background-attachment: fixed;
    }
    
    .ctaContent {
        position: relative;
        padding: 180px 0;
        z-index: 200;
        
        h4 {
            font-family: $primary;
            color: $white;
            font-size: 46px;
            margin-bottom: 25px;
        }
        .button {
            background: $red;
            color: $white;
            border-color: $red;
            margin: 0 10px!important;
            text-transform: uppercase;
            letter-spacing: $spaced;
            
            &:hover {
                background: transparent;
            }
            
            @include handheld {
                display: block;
                margin: 0 0 25px 0!important;
            }
        }
    }
    .badges {
        position: absolute;
        z-index: 100;
        bottom: 20px;
        right: 20px;

        @include handheld {
            left: 0;
            right: 0;
            text-align: center;
        }

        img {
            height: 70px;
            margin: 5px 10px;
        }
    }
}