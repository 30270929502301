.heroSlider {
    height: 65vh;
    min-height: 500px;
    overflow: hidden;
    
    @include touch {
        height: 400px;
        min-height: 400px;
    }
    
    .carousel-wrapper {
        .carousel {
            width: 100%;
            .carousel-cell {
                height: 65vh;
                min-height: 500px;
                width: 100%;
                @extend .backgroundSettings;
                
                @include touch {
                    height: 400px;
                    min-height: 400px;
                }
            }
            .flickity-viewport {
                width: 100%;
            }
            .flickity-page-dots {
                top: 20px;
                right: 20px;
                width: auto;
                
                @include handheld {
                    bottom: 20px;
                    top: unset;
                }
                
                .dot {
                    background: $white;
                    border-radius: 0;
                    height: 15px;
                    width: 15px;
                    opacity: .5;
                    margin: 0 0 0 10px;
                    @extend .smooth;
                    
                    @include handheld {
                        height: 12px;
                        width: 12px;
                        margin: 0 0 0 8px;
                    }
                    
                    &:hover {
                        opacity: .75;
                    }
                    &.is-selected {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
