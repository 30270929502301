nav {
    
    @include touch {
        display: none!important;
    }
    .subnav {
        display: none;
        position: absolute;
        background: $paper;
        z-index: 2;
        min-width: 130px;
        li {
            border-bottom: 1px solid $sand;
            &:last-child {
                border-bottom: none;
            }
            a {
                color: $red;
                padding: 10px;
                    
                &:hover {
                    color: $gold;
                }
            }
        }
    }
    .navContent {
        border-top: 1px solid $sand;
        
        .parent {
            float: left;
            border-right: 1px solid $sand;
            text-transform: uppercase;
            font-size: 16px;
            letter-spacing: .03em;
            @extend .smooth;
            
            &:hover {
                background: $paper;
            }
            &:last-of-type {
                border: none;
                .subnav {
                    right: 0;
                }
            }
            a {
                color: $red;
                display: block;
                padding: 16px;
                
                &:hover {
                    color: $gold;
                }
            }
        }
    }
}