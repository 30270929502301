.pageIntro {
    margin: 0;
    display: flex;
    background: $paper;
    flex-direction: row;
    width: 100%;
    
    @include touch {
        display: block;
    }
    
    .imageWrapper {
        @extend .backgroundSettings;
        width: 50vw;
        display: inline-block;
        
        @include touch {
            display: block;
            width: 100%;
            height: 350px;
        }
    }
    .sectionContent {
        padding: 64px;
        width: 50vw;
        display: inline-block;
        
        @include touch {
            display: block;
            width: 100%;
            padding: 32px;
        }
    }
    
    h1 {
        margin: 15px 0 40px 0;
    }
    h2 {
        margin: 15px 0 40px 0;
    }
    p {
        margin-bottom: 30px;
    }
    img {
        @include touch {
            margin-top: 30px;
        }
    }
}