.contentCards {
    margin-bottom: 64px;
}

.card {
    box-shadow: none;
    margin-bottom: 30px;
    position: relative;
    background: transparent;
    
    &.contentCard {
        .cardContent {
            h5 {
                margin-bottom: 15px;
            }
            h4 {
                font-family: $primary;
                font-size: 30px;
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 10px;
                
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
    &.featureCard {
        @extend .backgroundSettings;
        text-align: center;
        padding: 100px 20px;
        h4 {
            font-family: $primary;
            color: $white;
            font-weight: 600;
            text-transform: uppercase;
            position: relative;
            z-index: 200;
            margin-bottom: 10px;
        }
        h5 {
            color: $gold;
            opacity: 0;
            transform: translateY(10px);
            position: relative;
            z-index: 200;
            @extend .smooth;
            transition-delay: $delay;
        }
        
        &:hover {
            h5 {
                opacity: 1;
                transform: translateY(0);
            }
            .overlay {
                background: rgba(17, 17, 17, 0.7);
            }
        }
    }
    &.menuCard {
        @extend .backgroundSettings;
        height: 400px;
        border-radius: $radius;
        position: relative;
        margin-bottom: 0;
        
        .fa-file-pdf {
            color: $white;
            font-size: 24px;
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 50;
            box-shadow: $shadow;
        }
        .cardContent {
            position: absolute;
            width: 100%;
            bottom: 0;
            z-index: 500;
            padding: 20px;
            
            h3 {
                color: $white;
                float: left;
            }
            i {
                font-size: 22px;
                color: $gold;
                position: relative;
                top: 10px;
                float: right;
                opacity: 0;
                transform: translateX(-20px);
                @extend .smooth;
                transition-delay: $delay;
            }
        }
        .gradient {
            background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.01) 1%,rgba(0,0,0,0.45) 100%);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 5;
            transition: $transition ease-in-out;
        }
        .overlay {
            opacity: 0;
            background: rgba(17, 17, 17, 0.2);
        }
        &:hover {
            .overlay {
                opacity: 1;
            }
            i {
                opacity: 1;
                transform: translateY(0px);
            }
        }
    }
    &.eventCard {
        margin-bottom: 30px;
        .imageWrapper {
            width: 30%;
            margin-right: 30px;
            float: left;
            position: relative;
            
            @include touch {
                width: 100%;
                float: none;
                margin-bottom: 20px;
            }
            
            .date {
                background: $white;
                bottom: 15px;
                color: $red;
                font-size: .95em;
                font-weight: 600;
                padding: 10px 20px;
                position: absolute;
                text-transform: uppercase;
            }
        }
        .content {
            width: calc(70% - 30px);
            float: left;
            padding: 0;
            
            @include touch {
                width: 100%;
                float: none;
            }
            
            .venue {
                font-size: 1em;
                font-weight: 500;
                letter-spacing: .04em;
                margin-bottom: 5px;
                text-transform: uppercase;
                i {
                    color: $red;
                    margin-right: 4px;
                }
                &.whMark {
                    i {
                        color: $gold;
                    }
                }
                @include small-mobile {
                    font-size: .9em;
                    margin-bottom: 10px;
                }
            }
            h3 {
                color: $red;
                font-size: 1.8em;
                margin: 0 0 15px 0;
            }
            .times {
                margin-bottom: 0;
                
                span {
                    color: $gold;
                    font-weight: 500;
                    letter-spacing: -.05em;
                    margin: 0 6px;
                }
            }
            /*.price {
                margin-bottom: 15px;
            }*/
            .dateAlt {
                margin-bottom: 0;
            }
            .actions {
                margin-top: 15px;
            }
            a {
                background: $red;
                color: $white;
                font-size: .9em;
                font-weight: 600;
                letter-spacing: 1px;
                text-transform: uppercase;
                &:hover {
                    background: $gold;
                }
                &:first-of-type {
                    background: $black;
                    
                    &:hover {
                        background: $gold;
                    }
                }
                @include small-mobile {
                    margin-bottom: 6px;
                }
            }
        }
        /*&.archive {
            .times {
                margin-bottom: 15px;
            }
        }*/
    }
}