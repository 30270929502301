#lodging {
    .location {
        margin-bottom: 32px;
        
        &:last-of-type {
            margin-bottom: 64px;
        }
        
        .locationContent {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            
            h3 {
                font-size: 26px;
                text-transform: uppercase;
                font-weight: 600;
                margin-bottom: 20px;
            }
            .address {
                font-weight: 700;
                color: $blue;
                margin-bottom: 20px;
            }
            .phone {
                color: $gold;
                font-family: $primary;
                font-weight: 600;
                font-size: 26px;
                margin-bottom: 20px;
            }
            .description {
                margin-bottom: 20px;
            }
            .button {
                width: 200px;
            }
        }
    }
}