#parking {
    .splitView {
        .sectionContent {
            padding: 32px 64px 32px 0;
            
            @include touch {
                padding: 32px 0;
            }
        }
    }
    #cta {
        margin-top: 80px;
    }
}