.heroImage {
    height: 50vh;
    min-height: 400px;
    position: relative;
    @extend .backgroundSettings;
    
    @include screen {
        background-position: bottom!important;
        background-attachment: fixed;
    }
    @include small-desktop {
        background-attachment: scroll;
    }
    @include handheld {
        height: 300px;
    }
    
    //Not used on this site + conflicts
    /*h1 {
        background: $paper;
        padding: 10px 15px;
        position: absolute;
        left: 64px;
        bottom: 64px;
        
        @include tablet {
            left: 32px;
            bottom: 32px;
        }
        @include handheld {
            left: 0;
            bottom: 32px;
        }
    }*/
}