#seatingChart {
    .chart {
        background: $dkBlue;
        padding: 80px 0;
        
        img {
            margin: 0 auto;
            display: block;
            
            @include screen {
                width: 50%;
            }
            @include touch {
                width: 90%;
            }
        }
    }
    .preview {
        padding: 64px 0;
        
        h2 {
            text-align: center;
            margin-bottom: 64px;
        }
    }
}