#justAnnounced {
    .heroImage {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        width: 100%;
    }
    .heroEvent {
        width: 55vw;
        position: relative;
        left: 64px;
        
        @include small-desktop {
            width: 65vw;
        }
        @include touch {
            width: 75vw;
            left: 32px;
        }
        @include handheld {
            display: none;
        }
        h1 {
            background: $red;
            border-radius: $radius;
            color: $white;
            font-size: 32px;
            padding: 25px;
            margin-bottom: 20px;
        }
        .date {
            font-family: $primary;
            background: $paper;
            border-radius: $radius;
            color: $red;
            font-size: 26px;
            font-weight: 600;
            float: left;
            padding: 10px 15px 12px 15px;
            margin-right: 20px;
        }
        .button {
            float: left;
        }
    }
}