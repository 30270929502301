#history {
    .heroImage {
        height: 70vh;
    }
    .simpleIntro {
        @include screen {
            width: 45vw;
        }
    }
    .timeline {
        text-align: center;
        
        .milestone {
            width: 50%;
            margin: 0 auto;
            
            @include tablet {
                width: 80%;
            }
            @include handheld {
                width: 100%;
            }
            
            img {
                width: 100px;
                margin: 0 auto;
            }
            .year {
                font-family: $primary;
                font-size: 32px;
                font-weight: 700;
                color: $red;
                margin: 15px 0;
            }
        }
        .timePasses {
            width: 1.5px;
            height: 120px;
            margin: 40px auto;
            display: block;
            background: $gold;
        }
    }
    .credit {
        margin: 0 0 80px 0;
        p {
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
        }
    }
}