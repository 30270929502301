.social {
    margin-left: 6px;
    
    .orb {
        border-radius: $round;
        background: $red;
        text-align: center;
        position: relative;
        height: 26px;
        width: 26px;
        max-height: 26px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        
        @extend .smooth;
            &:hover {
                background: $gold;
            }
    }
    i {
        color: $white;
        font-size: 13.5px;
        line-height: 1em;
        position: relative;
        top: 2px;
    }
}