#upcomingShows {
    
    .eventIntro {
        background: $paper;
        padding: 0;
        
        .columns {
            width: 100%;
            margin: 0;
            
            .galleryCarousel {
                margin: 0;
                max-width: 100%;
                .carousel-cell {
                    background-size: cover;
                    background-repeat: no-repeat;
                    .eventTime {
                        background: $black;
                        border-right: $dkRed 4px solid;
                        color: $white;
                        font-weight: 700;
                        left: 0;
                        padding: 10px 30px;
                        position: absolute;
                        text-transform: uppercase;
                        top: 65px;
                        z-index: 10;
        
                    }
                    h2 {
                        font-size: 32px;
                        color: $white;
                        position: absolute;
                        text-align: center;
                        width: 100%;
                        bottom: 50px;
                        padding: 25px;
                        z-index: 15;
                    }
                    .gradient {
                        bottom: 52px;
                    }
                    .eventLinks {
                        background: $paper;
                        border-top: 6px $paper solid;
                        position: absolute;
                        bottom: 0px;
                        width: 100%;
                        
                        a {
                            background: $dkRed;
                            color: $white;
                            font-weight: 600;
                            display: inline-block;
                            padding: 15px;
                            text-align: center;
                            text-transform: uppercase;
                            width: 49.5%;
                            float: right;
                            
                            &:hover {
                                background: $gold;
                            }
                            &:first-child {
                                background: $black;
                                float: left;
                                
                                &:hover {
                                    background: $gold;
                                }
                            }
                        }
                    }
                }
                .flickity-button {
                    background: transparent;
                }
                .flickity-page-dots {
                    max-height: 40%;
                    right: 15px;
                    top: 15px;
                    width: 20px;
                    .dot {
                        background: $white;
                        margin-bottom: 6px;
                        opacity: .5;
                        &.is-selected {
                            opacity: 1;
                        }
                    }
                }
                @include touch {
                    display: none;
                }
            }
            .imageWrapper {
                width: 100%;
                height: 100%;
                @extend .backgroundSettings;
                
                @include touch {
                    height: 350px;
                }
                
                &.hideDesktop {
                    @include screen {
                        display: none;
                    }
                }
            }
            .upcomingEventContent {
                padding: 64px;
                
                @include touch {
                    padding: 32px;
                }
                h5 {
                    padding-bottom: 10px;
                }
                h1 {
                    padding-bottom: 40px;
                }
                p {
                    margin-bottom: 20px;
                }
                a {
                    color: $blue;
                    display: block;
                    font-size: 1.1em;
                    font-weight: 600;
                    
                    i {
                        color: $gold;
                        margin-left: 10px;
                        font-size: .9em;
                        @extend .smooth;
                    }
                    
                    &:hover {
                        i {
                            transform: translateY(2px);
                        }
                    }
                }
            }
        }
    }
    
}