#privateEvents {
    
    .eventTypes {
        background: $paper;
        padding: 64px 0 1px 0;
        
        @include touch {
            padding: 32px 0 1px 0;
            border-top: 2px solid $gold;
        }
        
        .overview {
            margin-bottom: 40px;
            h2 {
                margin-bottom: 25px;
            }
            p {
                margin-bottom: 25px;
                
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
    .planning {
        margin-bottom: 64px;
        .location {
            margin-bottom: 30px;
            .features {
                background: $paper;
                padding: 25px;
                
                h5 {
                    color: $red;
                    font-weight: 500;
                    margin-bottom: 25px;
                }
                ul {
                    li {
                        margin-bottom: 10px;

                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }   
                }
            }
        }
    }
}