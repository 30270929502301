#calendar {
    margin: 64px 0;

    h2 {
        margin-bottom: 32px;
    }

    .emptySearch {
        text-align: center;
        background: $paper;
        padding: 120px 80px;

        @include touch {
            padding: 64px;
        }
        @include handheld {
            padding: 40px;
        }

        i {
            color: $gold;
            font-size: 36px;
            margin-bottom: 20px;
        }
        h5 {
            color: $red;

            @include screen {
                width: 70%;
                margin: 0 auto;
            }
            @include tablet {
                width: 80%;
                margin: 0 auto;
            }
        }
    }
    .searchrow {
        margin-bottom: 64px;

        .label {
            color: $blue;
            text-transform: uppercase;
        }
        .iconPill {
            margin-top: 25px;
        }
        .control {
            span {
                cursor: pointer;
                pointer-events: visible;
            }
        }
        input {
            border: solid $dkRed 1px;
            border-radius: $radius;
        }
        .icon {
            i {
                color: $gold;
            }
        }
    }

    .searchPagination {
        border-top: 1px $gold solid;
        padding: 20px 0;
        .pages {
            font-family: $body;
            color: $blue;
            padding: 20px 10px 0;
            a {
                color: $blue;
                font-size: 1.2em;
                padding: 10px 15px;
                display: inline-block;

                &:hover {
                    color: $red;
                }
            }
            .current {
                color: $gold;
                font-size: 1.2em;
                padding: 10px 15px 0;
            }
        }
        .prev, .next {
            font-family: $body;
            padding: 0 10px;
            text-transform: uppercase;
            a {
                border: none;
                color: $blue;
                height: auto;
                padding: 10px 0 0;

                &:hover {
                    color: $red;
                }
            }
        }
    }
}
