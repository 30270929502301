#error {
    background: $paper;
    
    .contentWrapper {
        text-align: center;
        padding: 350px 0;
        
        h1 {
            margin-bottom: 40px;
        }
    }
}