#alert {
    background: $red;
    width: 100%;
    padding: 10px;
    text-align: center;
    position: relative;
    z-index: 500;
    @extend .smooth;
    
    .iconWrapper {
        min-width: 16px;
        min-height: 16px;
        display: inline-block;
        margin-right: 5px;
        
        @include touch {
            margin-top: 0;
        }
    }
    i {
        color: $white;
    }
    p {
        font-family: $body;
        font-weight: 600;
        color: $white;
        font-size: 16px;
        display: inline-block;
        
        @include mobile {
            font-size: 14px;
        }
        @include small-mobile {
            font-size: 14px;
        }
        @include xs-mobile {
            font-size: 13px;
        }
    }

    &.containsLink {
        &:hover {
            background: $dkRed;
            
            i {
                color: $white;
            }
        }
    }
}