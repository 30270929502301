#home {
    .intro {
        background: $paper; 
        padding: 64px 0 32px 0;
        
        .container {
            &.is-fluid {
                max-width: 1600px;
                margin: 0 auto;
            }
        }
        .is-flex {
            align-items: center;
        }
        
        .overview {
            @include touch {
                margin-bottom: 32px;
            }
        }
        h2 { 
            text-align: center;
            margin: 0 auto;
            
            @include screen {
                width: 50%;
            }
        }
        .fancyDivi {
            width: 150px;
            margin: 15px auto 64px auto;
            display: block;
        }
        
        h3 {
            margin-bottom: 30px;
            i {
                color: $gold;
                font-size: 24px;
                position: relative;
                top: -2px;
            }
        }
        p {
            margin-bottom: 30px;
        }
        .gallery {
            margin-top: 32px;
            
            @include handheld {
                display: none;
            }
        }
        .quickLinks {
            margin: 32px 0 0 0;
            text-align: center;
            
            @include touch {
                display: none;
            }
            
            li {
                display: inline-block;
                margin: 0 15px;
                text-align: center;
                
                .plain {
                    font-size: 16px;
                    text-transform: uppercase;
                    letter-spacing: .03em;
                    color: $red;
                    
                    &:hover {
                        color: $gold;
                    }
                }
            }
        }
    }
    .sectionTitle {
        font-size: 46px;
        text-align: center;
        padding: 64px 0;
        
        @include touch {
            font-size: 36px;
            padding: 40px 0;
        }
    }
    
    
    // Main Event Feature
    .eventFeature {
        padding: 64px 0;
        
        @include small-desktop {
            padding: 32px 0;
        }
        @include touch {
            padding: 32px 0;
        }
        
        .container {
            &.is-fluid {
                @include small-desktop {
                    padding-left: 32px;
                    padding-right: 32px;
                }

            }
        }
        
        .featuredEvent {
            height: 475px;
            margin-bottom: 7px;
            @extend .backgroundSettings;
            position: relative;
            
            @include small-desktop {
                height: 450px;
            }
            @include tablet {
                height: 450px;
            }
            @include mobile {
                height: 400px
            }
            @include small-mobile {
                height: 400px;
            }
            @include xs-mobile {
                height: 360px;
            }
            
            .eventTime {
                background: $black;
                border-right: $dkRed 4px solid;
                color: $white;
                font-weight: 700;
                padding: 10px 30px;
                position: absolute;
                text-transform: uppercase;
                top: 15px;
                z-index: 10;

            }
            h2 {
                font-size: 32px;
                color: $white;
                position: absolute;
                text-align: center;
                width: 100%;
                bottom: 0;
                padding: 25px;
                z-index: 15;
            }
        }
        .eventLinks {
            width: 100%;
            
            a {
                background: $dkRed;
                color: $white;
                font-weight: 600;
                display: inline-block;
                padding: 15px;
                text-align: center;
                text-transform: uppercase;
                width: 49.5%;
                float: right;
                
                @include handheld {
                    float: none;
                    display: block;
                    width: 100%;
                    margin-bottom: 7px;
                }
                
                &:first-child {
                    background: $black;
                    float: left;
                    
                    @include handheld {
                        float: none;
                    }
                }
                
                &:hover {
                    background: $gold;
                }
            }
        }
        .eventList {
            margin-bottom: -20px;
            h2 {
                margin: 0 0 32px 0;
                
                @include touch {
                    margin: 64px 0 32px 0;
                }
            }
            .box {
                border-radius: 0;
                border: solid 2px $gold;
                box-shadow: none;
                margin-bottom: 20px;
                padding: 0;
                @extend .smooth;
                
                &:hover {
                    background: $paper;
                    border-color: $red;
                }
                
                .media {
                    align-items: center;
                    .media-left {
                        margin: 0;
                        img {
                            max-width: 200px;
                        }
                    }
                    .media-content {
                        padding: 25px;
                        h4 {
                            font-family: $primary;
                            color: $dkRed;
                            margin: 0 0 10px 0;
                        }
                        p {
                            font-size: 16px;
                            font-weight: 500;
                            margin: 2px 0 0 0;
                        }
                    }
                }
            }
            .seeAll {
                display: block;
                margin: 30px 0 0 0; 
                font-weight: 700;
                text-align: center;
                text-transform: uppercase;
                color: $dkRed;
            }
        }
    }
}