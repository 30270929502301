h1,h2,h3,h4,h5,h6 {
    line-height: 1.1em;
    font-variant-numeric: lining-nums;
}
h1 { 
    font-family: $primary;
    font-size: 46px!important;
    font-weight: 700;
    color: $red;
    
    @include tablet {
        font-size: 44px!important;
    }
    @include mobile {
        font-size: 42px!important;
    }
    @include small-mobile {
        font-size: 38px!important;
    }
} 
h2 { 
    font-family: $primary;
    font-size: 38px;
    font-weight: 700;
    color: $red;
    
    @include handheld {
        font-size: 35px;
    }
} 
h3 { 
    font-family: $primary;
    font-size: 32px;
    font-weight: 500;
    color: $red;
} 
h4 { 
    font-family: $body;
    font-size: 30px;
    font-weight: 500;
    color: $red;
}
h5 { 
    font-family: $body;
    font-size: 16px;
    font-weight: 500;
    color: $blue;
    text-transform: uppercase;
    letter-spacing: $spacedX;
}
p { 
    font-family: $body;
    font-size: 20px;
    font-weight: 300;
    color: $black;
    line-height: 1.4em;
    
    @include small-mobile {
        font-size: 19px;
        line-height: 1.3em;
    }

    //Inline Links
    a {
        color: $gold;
        
        &:hover {
            color: $red;
        }
    }
}

//Base Links
a:link {
    text-decoration: none!important;
    @extend .smooth;
    color: $gold;
}
a:visited {
    color: $gold;
}
a:hover {
    color: $red;
}
a:active {
    color: $red;
}