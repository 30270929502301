.accordion {
    margin-bottom: 64px;
    
    .accordionBlock {
        border-bottom: 4px solid $white;
            .header {
                background-color: $paper;
                @extend .smooth;
               
                h4 {
                    font-size: 20px;
                    font-weight: 600;
                    width: 85%;
                    float: left;
                }
                i {
                    color: $gold;
                    font-size: 20px;
                    float: right;
                    position: relative;
                    top: 4px;
                    @extend .smooth;
                }
                &:hover {
                    background-color: $paper;
                }

                .accordion-button {
                    padding: 25px;
                    cursor: pointer;

                    .fa-plus {
                        backface-visibility: hidden;
                        transform: rotate(0deg);
                        transform-style: preserve-3d;
                        transition: transform $transition;
                    }

                    &.expanded {
                        .fa-plus {
                            transform: rotate(45deg);
                        }
                    }
                }
            }
            .accordion-content {
                background-color: $paper;
                overflow: hidden;
                transition: height .6s;

                .contentArea {
                    padding: 10px 25px 20px 25px;
                }
                p {
                    font-size: 18px;
                    margin-bottom: 25px;

                    &:last-of-type {
                        margin: 0;
                    }
                    &:empty {
                        margin: 0;
                    }
                }
                .button {
                    margin-top: 25px;
                }
            }
    }
}