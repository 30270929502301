.galleryCarousel {
    max-width: 80%;
    margin: 50px auto 70px auto;
            
    @include touch {
        max-width: 100%;
        margin: 30px auto 50px auto;
    }
    @include handheld {
        margin: 0 auto 30px auto;
    }
            
    .carousel-cell {
        height: 100%;
        img {
            display: block;
            max-height: 100%;
        }
    }
    .carousel-cell {
        width: 100%; 
        height: 500px;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
                
        @include small-desktop {
            height: 400px;
        }
        @include tablet {
            height: 360px;
        }
        @include mobile {
            height: 300px;
        }
        @include small-mobile {
            height: 280px;
        }
        @include mobile {
            height: 250px;
        }
    }

    &.is-fullscreen .carousel-cell {
        height: 100%;
    }
    .flickity-button {
        background: rgba(0, 0, 0, 0.5);
        color: $white;
        @extend .smooth;
                
        &:hover {
            background: rgba(0, 0, 0, 0.75);;
        }
    }
    .flickity-page-dots {
        .dot {
            width: 12px;
            height: 12px;
            margin: 0 6px;
            border-radius: 2px;
            background: $sand;
            opacity: 1;
            
            &.is-selected {
                background: $red;
            }
        }
    }
}