.eventFilters {
    .message-header {
        background: $dkBlue;
        p {
            color: $white;
            font-size: 25px;
            font-family: $primary;
            font-weight: 600;
        }
    }
    .message-body {
        background: $blue;
        color: $white;
        padding: 1.5em;
        a {
           border: solid 1px $gold;
        }
        .label {
            color: $white;
            font-size: .9em;
            letter-spacing: .15em;
            text-transform: uppercase;
        }
        .select {
            width: 100%;
            margin-bottom: 25px;
            select {
                width: 100%;
            }
        }
        input[type=submit] {
            background: transparent;
            border: solid 2px $gold;
            border-radius: $radius;
            color: $white;
            display: block;
            font-family: $body;
            font-size: 1.2em;
            font-weight: 600;
            margin: 0 auto;
            padding: 10px 20px;
            cursor: pointer;
            @extend .smooth;
            white-space: pre-wrap;

            &:hover {
                background: $gold;
            }
        }
    }
    .select:not(.is-multiple):not(.is-loading)::after {
        border-color: $gold!important;
    }

    /*@include touch {
        display: block;
    }

    .imageWrapper {
        @extend .backgroundSettings;
        width: 50vw;
        display: inline-block;

        @include touch {
            display: block;
            width: 100%;
            height: 350px;
        }
    }
    .sectionContent {
        padding: 64px;
        width: 50vw;
        display: inline-block;

        @include touch {
            display: block;
            width: 100%;
            padding: 32px;
        }

        h1 {
            margin: 15px 0 40px 0;
        }
        h3 {
            @include screen {
                width: 70%;
            }
            @include desktop {
                width: 85%;
            }
            font-size: 36px;
            font-weight: 700;
            margin: 15px 0 40px 0;
        }
        .date {
            margin-bottom: 20px; //for special events
        }
        p {
            margin-bottom: 20px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
        .button {
            margin-top: 30px;
        }
    }

    &.right {
        flex-direction: row;
    }
    &.left {
        flex-direction: row-reverse;
    }*/
}
